window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');
$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/cancelSubscription'));
    processInclude(require('./components/policyScroll'));
});

require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/collapse.js');
require('base/components/spinner');
require('./thirdParty/bootstrap');

