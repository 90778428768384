var productUrl,cancellinkID,cancellinkStatus;
$('body').on('click', '.cancel-subscription a', function (e) {
    e.preventDefault();
    $('.productName').text();
    $('.productName').text();
    $('.cancelConfirmation .product-image img').attr('src','');
    var productTitle=$(this).attr('data-product-title');
     productUrl=$(this).data('action');
    var productNamelowerCase= productTitle.toLowerCase();
    if( productNamelowerCase == "calisti") {
        $('.product-msg .calisti').removeClass('d-none');
        $('.product-msg .panoptica').addClass('d-none');
    } else {
        $('.product-msg .panoptica').removeClass('d-none');
        $('.product-msg .calisti').addClass('d-none');
    }
    $('.save-subscription').attr('data-action',productUrl);
    $('.productName').text(productTitle);
    var productImage=$(this).closest('.product-line-item-details').find('.product-image').attr('src');
    $('.cancelConfirmation .product-image img').attr('src',productImage).removeClass('d-none');
    $('#cancelSubscription').modal({
        show: true
       
    });
    cancellinkID= $(this).closest('.cancelSubslink').attr('id');
    cancellinkStatus= $(this).parent().find('.cancelSubsStatus').attr('id');
    $('.save-subscription').attr('data-cancellink',cancellinkID); 
    $('.save-subscription').attr('data-cancelstatus',cancellinkStatus); 
});
    $('body').on('click', '.cancelSubscription button.save-subscription', function (e) {
        e.preventDefault();
       
         $('body').spinner().start();
        $.ajax({
            url: productUrl,
            type: 'get',
            dataType: 'json',
          
            success: function (data) {
                $('body').spinner().stop();
                if (data.success) {
                    $('#cancelSubscription').modal('hide');
                    $('#'+cancellinkStatus).removeClass('d-none'); 
                    $('#'+cancellinkID).hide();  
              
                    $('#cancelConfirmation').modal({
                        show: true
                    });
                    
                } else {
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $('body').spinner().stop();
            }
        });
        return false;
    });


